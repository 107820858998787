<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <v-form>
        <template v-for="filter in filters">
          <form-subheader
            v-if="filter.header"
            :key="`${filter.id}_header`"
            :title="filter.header"
          />
          <form-date-picker
            v-if="filter.component.name === 'date'"
            :key="filter.id"
            v-model="state[filter.id]"
            :label="filter.component.label"
          />
          <v-select
            v-if="filter.component.name === 'select'"
            :key="filter.id"
            v-model="state[filter.id]"
            :label="filter.component.label"
            :items="filter.component.items"
            item-text="label"
            item-value="value"
          />
          <select-chips
            v-if="filter.component.name === 'tagSelect'"
            :key="filter.id"
            v-model="state[filter.id]"
            :color-chips="filter.component.colorChips"
            :label="filter.component.label"
            :items="filter.component.items"
            multiple
            item-text="name"
            item-value="value"
            chips
            @drop-item="handleDropChip(filter.id, $event)"
          />
        </template>
        <v-btn outlined block @click="handleClearAll">Clear all</v-btn>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="handleConfirm"
      >
        {{ confirmText }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import layout from './PopupLayoutDefault';
import cloneDeep from 'lodash.clonedeep';
import SelectChips from '@/components/_base/SelectChips.vue';
export default {
  name: 'PopupFilter',
  components: {
    SelectChips,
    layout
  },
  props: {
    title: {
      type: String,
      default: 'Filter'
    },
    value: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    },
    confirmText: {
      type: String,
      default: 'Apply'
    },
    onConfirm: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const state = ref({});
    const handleCancel = () => {
      emit('close');
    };
    const handleConfirm = async () => {
      try {
        loading.value = true;
        await props.onConfirm(state.value);
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };

    const handleClearAll = () => {
      state.value = props.filters.reduce((acc, filter) => {
        acc[filter.id] = filter.default;
        return acc;
      }, {});
    };

    const handleDropChip = (filterId, item) => {
      state.value[filterId]?.splice(
        state.value[filterId].findIndex(i => i.value === item.value),
        1
      );
    };

    onMounted(() => {
      state.value = cloneDeep(props.value);
    });

    return {
      handleClearAll,
      handleConfirm,
      handleCancel,
      handleDropChip,
      loading,
      state
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
